import React from 'react'

export const Usuario = () => {
  return (
    <div className='Cont'>
        <div>
            <p>
                NOMBRE:
            </p>
        </div>
    </div>
  )
}
