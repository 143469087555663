import { Link } from "react-router-dom"
import "./navbar.css"
import Modal from "../Modal/Modal"
import { MainLogin } from "../../pages/LogIn/LogIn";
import { FaUserAlt } from 'react-icons/fa'
import Modal1 from "../Modal1/Modal1";
import Modal2 from "../Modal2/Modal2";
import { UserDataContext } from "../../pages/Context/UserDataProvitder";
import React, { useState, useRef, useContext } from 'react'



const Navbar = () => {

    const {userData} = useContext(UserDataContext);

    const [modalOpen, setModalOpen] = useState(false);
    
    const openModal = () =>{
        setModalOpen(true)
    }
    const closeModal = () =>{
        setModalOpen(false)
    }
    const [modalOpen1, setModalOpen1] = useState(false);
    
    const openModal1= () =>{
        setModalOpen1(true)
        console.log(userData);
    }
    const closeModal1 = () =>{
        setModalOpen1(false)
    }
    const [modalOpen2, setModalOpen2] = useState(false);
    
    const openModal2= () =>{
        setModalOpen2(true)
    }
    const closeModal2 = () =>{
        setModalOpen2(false)
    }
    
    return (
        <div className="navbar">
            <div className="navContainer">
            
            <div className="logoContainer">
                <Link className="Home" to="/">
            <img src="https://res.cloudinary.com/dbenwgwfn/image/upload/v1684087408/Cancheros-Map/cancheros%20logos/logo.png" alt="" className="logo" ></img>
                </Link>
                </div>
                
            <div className="navItems">
            {/* <button className="navButton" onClick={openModal}>Inicio sesión</button> */}

            <Modal isOpen={modalOpen} onClose={closeModal}>
                    <MainLogin/>
                </Modal>
            </div>
            <div>
            <FaUserAlt className="Log" onClick={openModal1}/>

                <Modal1 isOpen={modalOpen1} onClose={closeModal1}>
                    <button className="BtnPer" onClick={openModal2}>Perfil</button>
                    <hr />
                    <Link to=  '/Salir'><button className="btnCer">Cerrar sesion</button></Link>
                    <Modal2 isOpen={modalOpen2} onClose={closeModal2}>
                        <div className="cont-perfil">
                        <div className="IMGPER">
                        <img src="https://res.cloudinary.com/dptohgiiu/image/upload/v1686344023/WhatsApp_Image_2023-06-09_at_3.53.21_PM_wngqvl.jpg" alt="" className='imgloga' />
                        </div>
                            <div className="infoPer">
                            <p className="pn">Nombre: {userData?.name}</p>
                            <p className="pg">Email: {userData?.email}</p>
                            </div>
                        </div>
                        </Modal2>
                </Modal1>
            </div>
            </div>
        </div>
    )
}

export default Navbar