import { useState } from "react";
import "./mailList.css"
import emailjs from '@emailjs/browser'

const MailList = () => {

    const [inputName, setInputName] = useState('');
    const [inputEmail, setInputEmail] = useState('');

    const sendEmail = (event) =>{

        event.preventDefault();

        emailjs.sendForm('service_aq0ycrj', 'template_zv9yek9',event.target,'4dSZoa3neoHEcpvYU')
        .then(response => console.log(response))
        .catch(error => console.log(error))
        handleButtonClick()
        // handleButtonClick()
    }

    const handleInputChange = (event) => {
        setInputName(event.target.value);
      };
    const handleInputEmail = (event) => {
        setInputEmail(event.target.value);
      };

    const handleButtonClick = () => {
        setInputName('');
        setInputEmail('');

      };

    return (
        <div className="mail">
            <h1 className="mailTitle">
                Ahorra tiempo, ahorra dinero!
            </h1>
            <span className="mailDesc">Inscribete y te enviaremos más ofertas</span>
            <form className="mailInputContainer" onSubmit={sendEmail}>
                <input value={inputName} type="text" placeholder="tu nombre" name='user_name'  onChange={handleInputChange}/>
                <input type="email" placeholder="tu correo" name='user_email' value={inputEmail} onChange={handleInputEmail}/>
                <button onChange={handleButtonClick} >Suscribete</button>
            </form>
        </div>
    )
}

export default MailList