import { BrowserRouter, Routes, Route,} from "react-router-dom";
import Home from "./pages/home/Home";
import List from "./pages/list/List";
import Cancha from "./pages/cancha/Cancha";
import { MainLogin } from "./pages/LogIn/LogIn";
import { Register } from "./pages/Register/Register";
import { Bernabeu } from "./components/Bernabeu/Bernabeu";
import { SanSiro } from "./components/SanSiro/SanSiro";
import { Campnou } from "./components/CampNou/CampNou";
import { Usuario } from "./components/Usuario/Usuario";

// import { Pasa1 } from "./components/Pasa1/Pasa1";

function App() {
  return (
    <div>
      <Routes>
      <Route path="/" element={<Home/>}/>
      <Route path="/canchas" element={<List/>}/>
      <Route path="/canchas/:id" element={<Cancha/>}/>
      <Route path="/login" element={<MainLogin/>}/>
      <Route path="/Register" element={<Register/>}/>
      <Route path="/CanchaBernabeu" element={<Bernabeu/>}/>
      <Route path="/CanchaCampnou" element={<Campnou/>}/>
      <Route path="/CanchaSanSiro" element={<SanSiro/>}/>
      <Route path="/PerfilUsuario" element={<Usuario/>}/>
      <Route path="/Salir" element={<Home/>}/>
      <Route path="/" element={<Home/>}/>
      {/* <Route path="/PasarelaDePagos" element={<Pasa1/>}/> */}
    </Routes>
    </div>
  )
}

export default App;
