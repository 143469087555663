// import { Link } from "react-router-dom"
import "./LogIn.css"
import Modal from "../../components/Modal/Modal"
import { Register } from "../Register/Register" 
import axios from 'axios'
import Swal from 'sweetalert2'
import { LoginAdmin } from "../LoginAdmin/LoginAdmin";
import { UserDataContext } from '../../pages/Context/UserDataProvitder';
import React, { useState, useRef, useContext } from 'react'

export const MainLogin = () => {

    const {updateUserData} = useContext(UserDataContext);

	//CONNECT BACK AND FRONT

	const [ inputs, setInputs ] = useState({
        email: "",
        password: ""
    });

    // const [ mensaje, setMensaje ] = useState();
    // const [ loading, setLoading ] = useState(false);

    const { email, password } = inputs;

    const onChange = (e) => {
        setInputs({...inputs, [e.target.name]: e.target.value});
    };

    const onSubmitLogin = async(e) => {
        try {
            e.preventDefault();
            const User = {
                email, password
            };
            console.log(User);
            const response = await axios.post("http://localhost:5000/api/users/login", User)
            updateUserData(response.data);
            alert("Se logueo")
        } catch (error) {
            alert("Error, verifica los datos")
        }
    };

    const mostrarAlerta=()=>{
    Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Bienvenido a Cancheros',
            showConfirmButton: false,
            timer: 2500,
    })
    }

	const [modalOpen, setModalOpen] = useState(false);
    const [modal2Open, setModal2Open] = useState(false);
	const [modalContent, setModalContent] = useState('');
    
    const openModal = () =>{
        setModalOpen(true)
        // setModalContent(content)
        setModalOpen(true)
    }
    const closeModal = () =>{
        setModalOpen(false)
    }

    const openModal2 = () => {
		setModal2Open(true);
	};
	
	const closeModal2 = () => {
		setModal2Open(false);
	};

return (
	<>
<div class="form-container">
	<p class="title">Inicio sesión</p>
	<form class="form" onSubmit={(e) => onSubmitLogin(e)}>
		<div class="input-group">
			<label for="username">Correo</label>
			<input onChange={(e)=> onChange(e)}  type="text" name="email" id="username" placeholder="Ingresa tu correo"/>
		</div>
		<div class="input-group">
			<label  for="password">Contraseña</label>
			<input onChange={(e)=> onChange(e)} type="password" name="password" id="password" placeholder="Ingresa tu contraseña"/>
			<div class="forgot">
				<a rel="noopener noreferrer" href="##">Olvidaste tu contraseña ?</a>
			</div>
		</div>
		<button onClick={()=>mostrarAlerta()} class="sign">Iniciar sesión</button>
	</form>
	<p class="signup">¿No tienes una cuenta?

		<button className="register" onClick={openModal}>Registrate</button>
            <Modal isOpen={modalOpen} onClose={closeModal}>
                <Register/>
            </Modal> 
	</p>
</div>
	</>
    )
}
    