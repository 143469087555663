import React from 'react'
import "./SanSiro.css"
import Navbar from "../navbar/Navbar"
import Header from "../header/Header"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import { faCircleArrowLeft, faCircleArrowRight, faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import {useState} from "react";
import { Map } from "../Map/Map"
import Modal from 'react-modal'
import {AiOutlineCloseCircle} from 'react-icons/ai'
import { loadStripe } from '@stripe/stripe-js'
import { Elements, CardElement, useStripe, useElements} from '@stripe/react-stripe-js'
import axios from 'axios'
import Swal from 'sweetalert2'



const stripePromise = loadStripe('pk_test_51NG6XgB439p1m1xwSpPezHZwMU1w00Eg2K7aK3qf92B9RoXHZcgIfmJfd8DDpjTOYNV7wfDkVGJkYAEN3zbQITU600cyjv6lC1')

const mostrarAlerta=()=>{
    Swal.fire({
            position: 'top-center',
            icon: 'success',
            title: 'Compra exitosa',
            showConfirmButton: false,
            timer: 2000,
    })
    }

const CheckoutForm = () =>{

    const stripe = useStripe();
    const elements = useElements();

    const handleSumbit = async (e) =>{
        e.preventDefault();
    
        const {error, paymentMethod} = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardElement)
        })

        if (!error){
            const { id } = paymentMethod;
            
            const { data }= await axios.post('http://localhost:5000/api/checkout',{
                id,
                amount: 90.000
            })
            console.log(data);

            elements.getElement(CardElement).clear();
    }
    }


    
    return <form onSubmit={handleSumbit} className='card'>
        <img src="https://res.cloudinary.com/dptohgiiu/image/upload/v1686344023/WhatsApp_Image_2023-06-09_at_3.53.21_PM_wngqvl.jpg" alt="" className='imglog' />
        <div className='Compra'>
            <h3 className='rsu'>Resumen de tu reserva</h3>
        <h3 className='con'>Concepto</h3>
        <p className='Pr'>Reserva de Gol Center 1 hora cancha futbol 8 hora 3:00 Pm</p>
        <hr />
        <h3 className='val'>Valor Reserva</h3>
        <p className='pre'>120.000</p>
        <img  className='paycan' src="https://res.cloudinary.com/dptohgiiu/image/upload/v1686631042/WhatsApp_Image_2023-06-12_at_11.37.09_PM_v9zj3c.jpg" alt="" />
        <p className='pcananchabuy'>Cancha futbol 8</p>
        <h3 className='text-center'>Total a pagar: 85.000</h3>
        <CardElement className='tarjeta'/>  
        <button onClick={()=>mostrarAlerta()} className='buycan' disabled={!stripe}>
            Reservar
        </button>
        </div>
    </form>
}


export const SanSiro = () => {
    const [slideNumber, setSlideNumber, newSlideNumber] = useState (0);
    const [open, setOpen] = useState (false);

    const photos = [
    {
        src: "https://res.cloudinary.com/dptohgiiu/image/upload/v1686630651/GOL6_lv5hlz.jpg"
    }, 
    {
        src:"https://res.cloudinary.com/dptohgiiu/image/upload/v1686630874/gol9_cxwmfx.jpg"
    }, 
    {
        src:"https://res.cloudinary.com/dptohgiiu/image/upload/v1686630651/GOL2_n8dce4.jpg"
    }, 
    {
        src:"https://res.cloudinary.com/dptohgiiu/image/upload/v1686630651/GOL1_s9pdmm.jpg"
    }, 
    {
        src:"https://res.cloudinary.com/dptohgiiu/image/upload/v1686630874/gol19_lt97ti.jpg "
    }, 
    {
        src:"https://res.cloudinary.com/dptohgiiu/image/upload/v1686630650/GOL5_uh7fbj.jpg"
    }, 
   
    ];

    const handleOpen = (i) =>{
        setSlideNumber(i);
        setOpen(true);
    };
    const handleMove = (direction)=>{
        
        let  newSlideNumber;

        if(direction==="l"){
            newSlideNumber = slideNumber === 0 ? 5 : slideNumber-1;
        } else{
            newSlideNumber = slideNumber === 5 ? 0 : slideNumber +1;
        }
        setSlideNumber(newSlideNumber)
    };

    const [modalIsOpen2, setIsOpen2] = React.useState(false);

    function openModal2() {
        setIsOpen2(true);
    }

    function closeModal2() {
        setIsOpen2(false);
    }

    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }
    return (
        <div>
            <Navbar/>
            <Header type="list"/>
            <div className="canchaContainer">
                {open && <div className="slider">
                    <FontAwesomeIcon icon={faCircleXmark} className="close" onClick={()=>setOpen(false)}/>
                    <FontAwesomeIcon icon={faCircleArrowLeft} className="arrow" onClick={()=>handleMove("l")}/>
                    <div className="sliderWrapper">
                        <img src={photos[slideNumber].src}  alt="" className="sliderImg" />
                    </div>
                    <FontAwesomeIcon icon={faCircleArrowRight}className="arrow" onClick={()=>handleMove("r")}/>
                </div>}
                <div className="canchaWrapper">
                    <h1 className="canchaTitle">San Siro</h1>
                    {/* <button className="reservaAhora">Reservar ahora</button> */}
                    <div className="canchaAddress">
                        <FontAwesomeIcon icon={faLocationDot}/>
                        <span>...</span>
                    </div>
                    <span className="canchaDistance">
                        Excelente localización - 250m del centro
                    </span>
                    <span className="canchaPriceHighlight">
                        si alquila por más de 1 hora en esta cancha se le dara un descuento de un 1% en el proximo alquiler
                    </span>
                    <div className="canchaImages">
                        {photos.map((photo,i)=>(
                            <div className="canchaImgWrapper">
                                <img onClick={()=>handleOpen(i)}src={photo.src} alt="" className="canchaImg" />
                            </div>
                        ))}
                    </div>
                    <div className="canchaDetails">
                        <div className="canchaDetailsTexts">
                            <h1 className="canchaTitle">
                                San Siro
                            </h1>
                            <p className="canchaDesc">
                            Canchas Sintéticas San Siro, Armenia, Quindío, Colombia está ubicado en Armenia, Quindío, Colombia, cerca de este lugar son: Gimnasio SuperGym (630 m), Centro Deportivo Mormones La Castellana (730 m), Edificio Castellana 9 A N 58 (976 m), Pulso Box armenia (1 km), GIMNASIO EQUILIBRIUM FITNESS (1 km).

                            </p>
                            </div>
                            <div className="canchaDetailsPrice">
                                <h1>Perfecta para jugar!</h1>
                                <span>
                                Cancha con • luces • tienda • gradas de puestos
                                </span>
                                <h2>
                                    $85.000 (1 hora)
                                </h2>
                                <button className="btnrese" onClick={openModal}>Reservar</button>
                            </div>
                            <div className="canchaDetailsPrice">
                                <h1>Perfecta para jugar!</h1>
                                <span>
                                Cancha con • luces • tienda • gradas de puestos
                                </span>
                                <h2>
                                    $150.000 (1 hora)
                                </h2>
                                <button className="btnrese" onClick={openModal2}>Reservar</button>
                            </div>
                        </div>
                    </div>
                    
                </div>
                    <Map/>

                    <Modal isOpen={modalIsOpen2}
                        onRequestClose={closeModal2}>
                            <button className='pointer' onClick={closeModal2}>
                        <AiOutlineCloseCircle></AiOutlineCloseCircle>
                        </button>
                            
                        <Elements stripe={stripePromise}>
                        <CheckoutForm/>
                        </Elements>
                        
                    </Modal>

                    <Modal 

                        isOpen={modalIsOpen}
                        onRequestClose={closeModal}
                    ><button className='pointer' onClick={closeModal}>
                        <AiOutlineCloseCircle></AiOutlineCloseCircle>
                        </button>
                        
                        <Elements stripe={stripePromise}>
                        <CheckoutForm/>
                        </Elements>
                        </Modal>
            </div>
    )
}
