import React  from 'react'
import './Register.css'
import Swal from 'sweetalert2'
import { useState } from "react";
import axios from 'axios'



export const Register = () => {

    const mostrarAlerta=()=>{
        Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Bienvenido a cancheros,',
            showConfirmButton: true,
            timer: 2000
    })
    }
    
    const [inputs, setInputs] = useState({
        name: '',
        email: '',
        password: '',
    })
    
    const { email, name, password } = inputs;
    // const [ loading, setLoading ] = useState(false);
    
    
    const onChange = (e) => {
        setInputs({...inputs, [e.target.name]: e.target.value});
    };
    
    const onSubmit = async(e) => {
        try {
            e.preventDefault();
            const User = {
                name, password, email
            };
            console.log(User);
            await axios.post("http://localhost:5000/api/users/register", User)
            // .then(({data}) => console.log(data));
        } catch (error) {
            alert("Error al registrar")
        }
        
    };
return (
    <>
    <div className='ContainerRegis'>

    <form class="form1" onSubmit={(e)=> onSubmit(e)}>
    <p class="title1">Registrate </p>
    <p class="message1">Inicia sesión para tener acceso completo a la aplicación. </p>
        <div class="flex">
        <label className='LabelInput'>
            <input onChange={(e)=> onChange(e)} name="name" required="" placeholder="" type="text" class="input1"/>
            <span className='SpanRegis'>Nombre Completo</span>
        </label>
    </div>  
            
    <label className='LabelInput'>
        <input onChange={(e)=> onChange(e)} name="email" required="" placeholder="" type="email" class="input"/>
        <span className='SpanRegis'>Correo</span>
    </label> 
        
    <label className='LabelInput'>
        <input onChange={(e)=> onChange(e)} name="password" required="" placeholder="" type="password" class="input"/>
        <span className='SpanRegis'>Contraseña</span>
    </label>
    <label className='LabelInput'>
        <input required="" placeholder="" type="password" class="input"/>
        <span className='SpanRegis'>Confirmar contraseña</span>
    </label>
    <button onClick={()=>mostrarAlerta()} class="submit"> Crear Cuenta </button>
</form>
</div>
</>
)
}
