import { Link } from "react-router-dom"
import "./canchasList.css"

const CanchasList = () => {
    return (
        <div className="cList">
            <div className="cListItem">
                <Link to='/CanchaCampnou'><img src="https://res.cloudinary.com/dptohgiiu/image/upload/v1686079029/35236561_384910932005016_7951147314406490112_n_hcavr0.jpg" alt="" className="cListImg" /></Link>
                <div className="cListTitles">
                <h1>Cancha Camp Nou</h1>
                    <h2>Futbol 7</h2>
                </div>
            </div>
            <div className="cListItem">
                <Link to='/CanchaCampnou'><img src="https://res.cloudinary.com/dptohgiiu/image/upload/v1686079029/35236561_384910932005016_7951147314406490112_n_hcavr0.jpg" alt="" className="cListImg" /></Link>
                <div className="cListTitles">
                <h1>Cancha Camp Nou</h1>
                    <h2>Futbol 5 </h2>
                    
                </div>
            </div>
            <div className="cListItem">
                <Link to='/CanchaBernabeu'><img src="https://res.cloudinary.com/dptohgiiu/image/upload/v1686344484/WhatsApp_Image_2023-06-09_at_4.00.48_PM_lkblfl.jpg" alt="" className="cListImg" /></Link>
                <div className="cListTitles">
                <h1>Cancha Bernabeu</h1>
                    <h2>Futbol 8</h2>
                
                </div>
            </div>
            <div className="cListItem">
                <Link to='/CanchaSanSiro' ><img src="https://res.cloudinary.com/dptohgiiu/image/upload/v1686631042/WhatsApp_Image_2023-06-12_at_11.37.09_PM_v9zj3c.jpg" alt="" className="cListImg" /></Link>
                <div className="cListTitles">
                    <h1>Cancha San Siro</h1>
                    <h2>Futbol 7</h2>
                </div>
            </div>
        </div>
    )
}

export default CanchasList